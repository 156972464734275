<template>
  <div>
    <div class="all-height d-flex justify-center">
   <div class="all-width">
     <v-card :loading="loading" :disabled="loading" loader-height="2">
       <v-card-text>
         <div class="d-flex align-center">
           <v-btn class="shadow-off" text @click.stop="$nova.gotoLink({path: backlink})" icon>
             <v-icon>mdi-arrow-left</v-icon>
           </v-btn>
           <v-btn class="shadow-off" text @click.stop="refreshData()" icon>
             <v-icon>mdi-refresh</v-icon>
           </v-btn>
         </div>
         <v-stepper v-model="e1" class="shadow-off">
           <v-stepper-header class="shadow-off px-0 mx-0">
             <v-stepper-step :complete="e1 > 1" step="1"> Step 1 </v-stepper-step>
             <v-divider></v-divider>
             <v-stepper-step :complete="e1 > 2" step="2"> Step 2 </v-stepper-step>
             <v-divider></v-divider>
             <v-stepper-step step="3"> Step 3 </v-stepper-step>
           </v-stepper-header>
           <v-stepper-items>
             <v-stepper-content step="1">
               <div class="d-flex align-center mb-5">
                 <v-spacer></v-spacer>
                 <v-btn small color="primary" :disabled="!dataTable.length > 0" @click="nextStepOne()">Next Step</v-btn>
               </div>
               <div class="d-flex align-center mt-4">
                 <v-row>
                   <v-col cols="6" class="my-0 py-0">
                     <lb-dropdown label="File Name" v-model="selectFileName" :items="fileItems" itemtext="name" itemvalue="_id"  :disabled="true"/>
                   </v-col>
                   <v-col cols="3" class="my-0 py-0">
                     <lb-number label="Ignore Rows" v-model="ignoreRow" min="0" @change="getDataSlice()" />
                   </v-col>
                   <v-col cols="3" class="my-0 py-0">
                     <lb-number label="Ignore Colums" v-model="ignoreColums" @change="getDataSlice()" min="0" :max="Math.max(displaytablecolumn - 1, 0).toString()" />
                   </v-col>
                   <v-col cols="4" class="my-0 py-0">
                     <lb-dropdown label="Blank Column Headers" :items="blankheadersItem" v-model="blankheaders" />
                   </v-col>
                   <v-col cols="6" class="d-flex pt-0 mt-0"> Blank rows will be ignored. </v-col>
                 </v-row>
               </div>
               <div class="d-flex align-center" v-if="showHide">
                 <span class="mr-4">File Upload</span>
                 <lb-file v-model="fileSelect" label="" class="py-0 my-0" :drag="false" hidedetails :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb,.csv" @change="selectFileMe()" />
                 <span v-if="nameFile">
                   <v-chip class="ma-2" small close @click:close="removeFile()" label>
                     <v-icon left> mdi-file </v-icon>
                     {{ nameFile }}
                   </v-chip>
                 </span>
               </div>
               <div v-if="nameFile && getFinalHeaders.length > 0" class="mb-3">
                 <div class="font-weight-black mb-1">Final Headers</div>
                 <v-chip v-for="(colum, i) in getFinalHeaders" :key="'B' + i" class="ma-1" label text-color="black" color="blue-grey lighten-5" x-small dense>
                   {{ colum }}
                 </v-chip>
               </div>
               <div v-if="dataTable.length > 0">
                 <v-simple-table dense v-if="dataTable && showHide" class="mb-3 custom-data-table">
                   <thead class="blue-grey lighten-4 align-center font-weight-black">
                     <th></th>
                     <template v-for="vv in displaytablecolumn">
                       <th class="text-center pa-1" :key="vv" v-if="vv >= ignoreColums">
                         {{ $nova.numToletter(vv) }}
                       </th>
                     </template>
                   </thead>
                   <tbody>
                     <template v-for="(v, k) in displaytable">
                       <tr :key="'B' + k">
                         <th id="IgnoreRow" class="font-weight-black px-1 py-0 text-center">
                           {{ k + 1 + Math.max(ignoreRow - 5, 0) }}
                         </th>
                         <template v-for="vv in displaytablecolumn">
                           <td class="px-1 py-0" v-if="vv >= ignoreColums" :key="vv" :style="{
                                  'background-color': `${
                                    (ignoreRow || 0) -
                                      Math.max(ignoreRow - 5, 0) ===
                                      k &&
                                      vv > ignoreColums
                                      ? 'yellow'
                                      : ''
                                  }`,
                                }">
                             {{ v[vv-1] || "&nbsp;" }}
                           </td>
                         </template>
                       </tr>
                     </template>
                   </tbody>
                 </v-simple-table>
               </div>
             </v-stepper-content>
             <v-stepper-content step="2">
               <div class="mb-7">
                 <div class="subtitle-2 mb-2">Map Columns:</div>
                 <div class="font-weight-black"> Available columns in sample uploaded </div>
                 <v-chip v-for="(colum, i) in selectColums" :key="i" draggable class="ma-1 cursor-drag" label text-color="black" color="blue-grey lighten-5" @dragstart="startDrag($event, colum, i)">
                   <v-icon left> mdi-drag </v-icon>
                   {{ colum }}
                 </v-chip>
               </div>
               <div>
                 <div class="font-weight-black mt-5">Required columns</div>
                 <v-checkbox label="Create Unmapped Columns" class="mt-0 mb-2" :ripple="false" dense hide-details v-model="createColumns"></v-checkbox>
					<div class="d-flex flex-row flex-wrap mb-4">
						<div v-for="(fileCol, i) in fileColums" :key="i" class="mx-2">
							<div class="d-flex align-center mb-2">
							<v-chip label class="d-block" outlined color="primary" style="width: 100px;">
								{{ fileCol.name }}
							</v-chip>
							<div style="width: 10px">
								<v-divider horizontal></v-divider>
							</div>
							<div :class="`drag-droppable py-1 px-2 ${
													(twoDiArray[i] || [])[1] ? 'blue-grey lighten-5' : ''
												}`" style="min-width: 200px; border: 2px dashed #ccc" @drop="onDrop($event, fileCol.name, i)" @dragover.prevent @dragenter.prevent>
								<div class="d-flex justify-center">
								<v-icon left v-if="(twoDiArray[i] || [])[1]">mdi-drag</v-icon>
								<span v-html="(twoDiArray[i] || [])[1] || '&nbsp;'"></span>
								<v-spacer></v-spacer>
								<v-btn v-if="(twoDiArray[i] || [])[1]" icon x-small @click="removeItem((twoDiArray[i] || [])[1], i)">
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
								</div>
							</div>
							</div>
						</div>
					</div>
               </div>
			<div v-for="(col, key) in tempuserColMapColumns" :key="key" class="mx-2">
				<v-row>
					<v-col cols="6" sm="3">
						<div> 
							{{ col.text }}
						</div>
					</v-col>
					<v-col cols="6" sm="3">
						<lb-dropdown v-model="tempuserColMapColumns[key].value" :items="tempuserColMapColumns[key].dropdown_options" :multiselect="false" :value = "col.value"  width="10" @change="setUserColMapColumnsDropdownValues(key,col.value)"></lb-dropdown>
					</v-col>
				</v-row>
			</div>
               <v-btn small class="mr-2" color="primary" @click="backOneStep">Back</v-btn>
               <v-btn small color="primary" @click="nextStepTwo()">Next Step</v-btn>
             </v-stepper-content>
             <v-stepper-content step="3">
				<div class="mb-7">
					<div class="subtitle-2 mb-2">Configuration Summary:</div>
					<div class="font-weight-black mb-4">Basic Details</div>
					<div class="mb-4">
					<div class="font-weight-bold"> File Name : <span class="font-weight-regular">{{ nameFile }}</span>
					</div>
					<div class="font-weight-bold"> Ignore Rows : <span class="font-weight-regular">{{ ignoreRow }}</span>
					</div>
					<div class="font-weight-bold"> Ignore Column : <span class="font-weight-regular">{{ ignoreColums
							}}</span>
					</div>
					</div>
					<div class="font-weight-black mb-2">Column Mapping</div>
					<div class="d-flex">
					<div>
						<div v-for="(fileCol, i) in fileColums" :key="i" class="my-1">
						<div class="d-flex align-center">
							<v-chip v-if="(twoDiArray[i] || [])[1]" label class="d-block flex-grow-1" outlined color="primary">
							{{ fileCol.name }}
							</v-chip>
							<div style="width: 50px" v-if="(twoDiArray[i] || [])[1]">
							<v-divider></v-divider>
							</div>
							<div v-if="(twoDiArray[i] || [])[1]" :class="`drag-droppable py-1 px-2 ${
									(twoDiArray[i] || [])[1]
									? 'blue-grey lighten-5'
									: ''
								}`" style="min-width: 200px; border: 2px dashed #ccc" @drop="onDrop($event, fileCol.name, i)" @dragover.prevent @dragenter.prevent>
							<div class="d-flex justify-center">
								<span v-html="(twoDiArray[i] || [])[1] || '&nbsp;'"></span>
								<v-spacer></v-spacer>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
					<div class="font-weight-black mb-2">User Column Mapping</div>
					<div class="d-flex">
                        <div>
                          <div class="d-flex">
                            <div>
                              <div
                                v-for="(fileCol, i) in userMappedColumns"
                                :key="i"
                                class="my-1"
                              >
                                <div class="d-flex align-center">
                                  <v-chip
                                    label
                                    class="d-block flex-grow-1"
                                    outlined
                                    color="primary"
                                  >
                                    {{ fileCol[1] }}
                                  </v-chip>
                                  <div style="width: 50px">
                                    <v-divider></v-divider>
                                  </div>
                                  <div
                                    :class="`drag-droppable py-1 px-2 blue-grey lighten-5`"
                                    style="min-width: 200px; border: 2px dashed #ccc"
                                    @drop="onDrop($event, fileCol.name, i)"
                                    @dragover.prevent
                                    @dragenter.prevent
                                  >
                                    <div class="d-flex justify-center">
                                      <span v-html="fileCol[0] || '&nbsp;'"></span>
                                      <v-spacer></v-spacer>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
					<!-- e -->
					<div class="mb-2 mt-4">
					<div class="font-weight-black mb-4">Removed Columns</div>
					<v-chip v-for="(colum, i) in selectColums" :key="'B' + i" class="ma-1" label text-color="black" color="blue-grey lighten-5">
						{{ colum }}
					</v-chip>
					</div>
					<div v-if="unMapedColumc.length > 0" class="mb-2">
					<div class="font-weight-black mb-4">Create Columns</div>
					<v-chip v-for="(colum, i) in unMapedColumc" :key="'C' + i" class="ma-1" label text-color="black" color="blue-grey lighten-5">
						{{ colum }}
					</v-chip>
					</div>
					<div v-else class="mb-2">
					<div class="font-weight-black mb-4" v-if="ignoredColumns.length > 0"> Ignored Columns </div>
					<v-chip v-for="(colum, i) in ignoredColumns" :key="'D' + i" class="ma-1" label text-color="black" color="blue-grey lighten-5">
						{{ colum }}
					</v-chip>
					</div>̥
				</div>
               <v-btn small color="primary" class="mr-2" @click="backTwoStep">Back</v-btn>
               <v-btn small color="primary" @click="lastStep()">Finish</v-btn>
             </v-stepper-content>
           </v-stepper-items>
         </v-stepper>
       </v-card-text>
     </v-card>
   </div>
 </div>

 <lb-dialog v-model="alreadyConfig" width="400" heading="Information" >
    <template v-slot:body>
      <div>
        <div class="d-flex align-center justify-center">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
                Already configured file
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn small color="primary">
        <span @click.stop="$nova.gotoLink({path: configlink})">Ok</span>
      </v-btn>
    </template>
  </lb-dialog>
  </div>
</template>

<script>
export default {
	data() {
		return {
            backlink:"/bot/filemaster",
            configlink:"/bot/filelist",
			ignoreRow: 0,
			ignoreColums: 0,
			loading: false,
			e1: 1,
			nameFile: "",
			fileItems: [],
			selectFileName: null,
			fileSelect: null,
			dataTable: [],
			showHide: true,
			fileColums: [],
			selectColums: [],
			columListMap: [],
			totalMapColum: [],
			twoDiArray: [],
			twoItem: null,
			createColumns: false,
			unMapedColumc: [],
			ignoredColumns: [],
			displaytable: [],
			displaytablecolumn: 0,
			mapedColumns: [],
			analysisDate: [],
			blankheadersItem: ["left", "right", "top", "bottom"],
			blankheaders: "left",
			blankRows: true,
			duplicateColumns: false,
			blankheadersValue: 0,
            alreadyConfig:false,
			tempuserColMapColumns:{
				"company_code":{
					"text":"Company code/name (Combo code)",
					"value": "string1",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],
				},
				"related_party_code":{
					"text":"Related party code/name (Combo code)",
					"value": "string2",
					"dropdown_options":["string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'],
				},
				"customer_code":{
					"text":"Customer code  (FIFO)",
					"value": "string3",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],
				},
				"customer_name":{
					"text":"Customer name  (FIFO)",
					"value": "string4",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],
				},
				"amounts":{
					"text":"Amount to display in the summary and reconciliation tab",
					"value": "amount1",
					"dropdown_options":[
						"amount1","amount2","amount3","amount4","amount5","amount6","amount7","amount8","amount9"
					],
				},
				"vendor_code":{
					"text":"Vendor code  (FIFO)",
					"value": "",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],				},
				"vendor_name":{
					"text":"Vendor name  (FIFO)",
					"value": "",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],
				},
				"summary_combo_code":{
					"text":"Summary combo code, and this column should be split",
					"value": "",
					"dropdown_options":[],
				},
				"invoice_date":{
					"text":"Invoice date",
					"value": "date1",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],
				},
				"collection_date":{
					"text":"Collection date ",
					"value": "date2",
					"dropdown_options":[
						"string1","string2","string3","string4","string5","string6","string7","string8","string9","string10","string11",'date1','date2','date3'
					],
				},
			},
			userColMapColumns:  {},
			userMappedColumns: []
		};
	},
	created() {
		this.refreshData();
		this.userColMapColumns= JSON.parse(JSON.stringify(this.tempuserColMapColumns)) || {}
	},
	activated() {
		this.refreshData();
	},
	watch: {
		ignoreRow() {
			this.getDataSlice();
		},
		ignoreColums() {
			this.getDataSlice();
		}
	},
	methods: {
		refreshData() {
			this.loading = true;
			this.axios
				.post("/v2/conserve/filemaster/get")
				.then((dt) => {
					if (dt.data.status === "success") {
						this.fileItems = dt.data.data;
						let getfile = this.fileItems.filter((x) => x._id === this.$route.params.conid);
						this.fileColums = getfile[0].columns;
						this.selectFileName = this.$route.params.conid;
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				})
		},
		selectFileMe() {
			if (this.fileSelect) {
				this.nameFile = this.fileSelect?.name;
				let file = this.fileSelect;
				Promise.resolve()
					.then(() => {
						let ext = ((file || {}).name || "").split(".").pop();
						if (!file) throw new Error("This file is required!");
						else if (!file.name) throw new Error("Upload a valid file");
						else if (["xls", "xlsx", "xlsb"].includes(file.name.split(".").pop().toLowerCase()) && file.size > 1024 * 1024 * 2)
							throw new Error("File with size > 2MB should be in csv format");
						else if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) > -1)
							return this.$nova.readExcelFile(file, "", {
								removeblankrows: true,
								columncount: this.displaytablecolumn || 0
							});
						else if (["csv"].indexOf(ext) > -1) return this.$nova.readCSV(file, {
							removeblankrows: true,
							columncount: this.displaytablecolumn || 0
						});
						throw new Error("Upload a valid file");
					})
					.then((data) => {
						this.dataTable = data;
						this.getDataSlice();
					})
					.catch((err) => {
						this.$store.commit(
							"sbError",
							err.message || err || "Unknown error!"
						);
						console.log(err);
					})
					.finally(() => {
						console.log("final");
					});
			}
		},
		getDataSlice() {
			this.displaytable = this.dataTable;
			this.displaytablecolumn = 0;
			this.displaytable = this.displaytable.slice(Math.max(this.ignoreRow - 20, 0), Math.min(this.ignoreRow + 20, this.dataTable.length));
			for (const i of this.displaytable) {
				this.displaytablecolumn = Math.max(this.displaytablecolumn, i.length);
			}
		},
		removeFile() {
			this.fileSelect = null;
			this.nameFile = "";
			this.dataTable = [];
			this.ignoreRow = 0;
			this.ignoreColums = 0;
		},
		nextStepOne() {
			this.selectColums = this.getFinalHeaders;
			this.arrayCreat();
			this.e1 = 2;
		},

		renameFiles(arr) {
			let count = {};
			arr.forEach(function(x, i) {
				if (arr.indexOf(x) !== i) {
					count[x] = count[x] || 0;
					count[x] += 1;
					// x in count ? (count[x] = count[x] + 1) : (count[x] = 1);
					let c = count[x];
					let j = c + 1;
					let k = x + (j - 1);
					while (arr.indexOf(k) !== -1) k = x + "(" + ++j + ")";
					arr[i] = k;
				}
			});
			return arr;
		},
		startDrag(evt, item) {
			this.twoItem = item;
			evt.dataTransfer.dropEffect = "move";
			evt.dataTransfer.effectAllowed = "move";
		},
		onDrop(evt, list, index) {
			if (this.twoDiArray[index]) {
				let Itemname = this.twoDiArray[index];
				if (this.twoDiArray[index].length === 2) {
					let popItem = Itemname.pop();
					this.selectColums.push(popItem);
				}
				Itemname.push(this.twoItem);
			}
			const indexItem = this.selectColums.indexOf(this.twoItem);
			if (indexItem > -1) {
				this.selectColums.splice(indexItem, 1);
			}
			// console.log(this.twoDiArray);
		},
		arrayCreat() {
			for (const i of this.fileColums) {
				this.twoDiArray.push([i.name]);
			}
		},
		backOneStep() {
			this.twoDiArray = [];
			this.e1 = 1;
		},
		backTwoStep() {
			this.unMapedColumc = [];
			this.ignoredColumns = [];
			this.e1 = 2;
		},
		removeItem(item, i) {
			this.selectColums.push(item);
			if (this.twoDiArray[i]) {
				this.twoDiArray[i].pop();
			}
		},
		nextStepTwo() {
			console.log(this.fileColums,"fileColums");
			console.log(this.twoDiArray,"this.twoDiArray");

			let matchornot = this.twoDiArray.some(subArray => subArray.length === 2);
			console.log(matchornot,"matchornot");					 
			if (this.createColumns) {
				for (let j = 0; j < this.twoDiArray.length; j++) {
					if (this.twoDiArray[j].length === 1) {
						this.unMapedColumc.push(this.twoDiArray[j][0]);
					}
				}
			} else {
				for (let j = 0; j < this.twoDiArray.length; j++) {
					if (this.twoDiArray[j].length === 1) {
						this.ignoredColumns.push(this.twoDiArray[j][0]);
					}
				}
			}
			this.mapedColumns = [];
			for (let k = 0; k < this.twoDiArray.length; k++) {
				if (this.twoDiArray[k].length === 2) {
					this.mapedColumns.push(this.twoDiArray[k]);
				}else{
					console.log("FFFFFFFFF");
					
				}
			}
			this.userMappedColumns = [];
			let index = 0
			Object.keys(this.tempuserColMapColumns).forEach((key)=>{
				if(this.tempuserColMapColumns[key].value && this.tempuserColMapColumns[key].value !== ""){
					this.userMappedColumns[index] = []
					this.userMappedColumns[index].push(this.tempuserColMapColumns[key].value);
					this.userMappedColumns[index].push(this.tempuserColMapColumns[key].text);
					index++;
				}
			})
			if (matchornot) {
				this.e1 = 3;
			} else {
				this.$store.commit("sbError", "At least one column must be mapped");
			}
			//this.e1 = 3;
		},
		lastStep() {
			let data = {
				file: this.selectFileName,
				ignorerows: this.ignoreRow,
				ignorecolumns: this.ignoreColums,
				columnmap: this.mapedColumns,
				usercolumnmap: this.userMappedColumns,
				ignoredcolumns: this.ignoredColumns,
				unusedcolumns: this.unMapedColumc,
				createcolumns: this.createColumns,
				blankcolumnheaders: this.blankheaders,
				removeblankrows: this.blankRows,
				keepduplicatecolumns: this.duplicateColumns,
			};
			this.loading = true;
			this.axios
				.post("/v2/conserve/fileconfig/add/", {
					data
				})
				.then((dt) => {
					if (dt.data.status === "success") {
						this.analysisDate = dt.data.data;
						this.selectFileName = "";
						this.e1 = 1;
						this.$nova.gotoLink({
							path: this.configlink
						})
					} else if (dt.data.data.length > 0) {
                        if (dt.data.data[0].index0.file[0] === 'already exist'){
                            this.alreadyConfig = true;
                        }
                    } else throw  new Error(dt.data.message || "Error Add File Config");
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});

		},
		setUserColMapColumnsDropdownValues(key,value){
			if(value !== null && value !== ''){
				Object.keys(this.tempuserColMapColumns).forEach((itemKey)=>{
					if(itemKey !== key){
						this.tempuserColMapColumns[itemKey].dropdown_options = this.tempuserColMapColumns[itemKey]?.dropdown_options.filter((opt)=>opt !== value)
					}
					this.userColMapColumns[key].value = value || ""
				});
			}else{
				if(this.userColMapColumns[key]?.value !== null && this.userColMapColumns[key]?.value !== ""){
					Object.keys(this.tempuserColMapColumns).forEach((item)=>{
						if(item !== key && this.userColMapColumns[item].dropdown_options.indexOf(this.userColMapColumns[key]?.value) !== -1){
							this.tempuserColMapColumns[item].dropdown_options = [...this.tempuserColMapColumns[item].dropdown_options,...[this.userColMapColumns[key]?.value]]
						}
					})
					this.userColMapColumns[key].value = ""
				}
			}
		}
	},
	computed: {
		getFinalHeaders() {
			let selectRow = [...(this.dataTable[this.ignoreRow] || [])];
			let copyindex = 0;
			if (this.blankheaders === "bottom") copyindex = 1;
			else if (this.blankheaders === "top") copyindex = -1;
			let blankRow = [...(this.dataTable[this.ignoreRow + copyindex] || [])];

			let selectColumsOne = selectRow.slice(this.ignoreColums) || [];
			let blankColum = blankRow.slice(this.ignoreColums) || [];
			let finalColum = [];
			for (let r = 0; r < (this.displaytablecolumn || selectColumsOne.length); r++) {
				if ((selectColumsOne[r] || "") == "") {
					if (["bottom", "top"].indexOf(this.blankheaders) > -1)
						finalColum.push(blankColum[r] ?? "");
					else if (this.blankheaders === "left")
						finalColum.push(finalColum[r - 1] ?? "");
					else finalColum.push(selectColumsOne[r]);
				} else {
					finalColum.push(selectColumsOne[r]);
				}
			}
			for (let r = selectColumsOne.length - 1; r > -1; r--) {
				if (selectColumsOne[r] == "") {
					if (this.blankheaders === "right")
						finalColum[r] = finalColum[r + 1] ?? "";
				}
			}
			// console.log(finalColum);
			finalColum = finalColum.map((x) => (x || "").toString().trim());
			/* Unnamed Columns Create */
			let addfinalCol = finalColum.map(unkownColum).map(item => item.replace('.', ''));
			function unkownColum(item) {
				if (item == "") {
					return "Unnamed";
				} else {
					return item;
				}
			}
			/* Duplicate Columns Create */
			let finalheaders = [];
			if (!this.duplicateColumns) {
				finalheaders = this.renameFiles(addfinalCol);
			} else {
				finalheaders = addfinalCol;
			}
			return [...finalheaders];
		},
	},
};
</script>

<style lang="scss" scoped>

.custom-data-table {
  & td,
  & th {
    border: 1px solid #bbb !important;
  }
}
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
.all-height{
  height: 95%;
}
</style>
